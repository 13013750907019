import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://192.168.196.4:8085';
const client = axios.create({
    baseURL: `${BASE_URL}/user`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const login = async (email: string, password: string) => {
    let response: any;
    
    response = await client.post('/login', {
        email: email,
        password: password,
    });
    console.log(response.data);
    
    return response.data;
};

export const register = async (email : string, password : string, firstName: string, lastName: string, birthDate: string) => {
    try {
        const response = await client.post('/register', {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            birthDate: birthDate
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data?.message || error.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const updateUser = async (email: string, firstName: string, lastName: string, phoneNumber: string, birthDate: string) => {
    try {
        const response = await axios(`${BASE_URL}/user/update` ,{
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                birthDate: birthDate
            }
        ,
        withCredentials: true
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data?.message || error.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
}

export const getUser = async () => {
    try{
        const response = await axios(`${BASE_URL}/user/get-user`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data?.message || error.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const isEmployer = async (userId : string) => {
    try {
        const response = await axios(`${BASE_URL}/employer/is-employer/` + userId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data?.message || error.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
