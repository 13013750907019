import React from "react";
import Container from 'react-bootstrap/Container';
import Filters from "../../Components/Offers/Filters";
import OffersListView from "../../Components/Offers/OffersListView";
import { OffersPageContentProps } from "../../types";



const OffersPageContent = (props: OffersPageContentProps) => {
    return (
        <Container className='content-pane'>
            <Filters
                offersPerPage={props.offersPerPage}
                handleOffersPerPageChange={props.handleOffersPerPageChange}
            />
            <Container className='main-content'>
                <OffersListView
                    loading={props.loading}
                    currentOffers={props.currentOffers}
                    totalPages={props.totalPages}
                    currentPage={props.currentPage}
                    handlePageChange={props.handlePageChange}
                    manageMode={props.manageMode}
                />
            </Container>
        </Container>
    );
}

export default OffersPageContent;