import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Styles/OfferCard.scss';
import { OfferCardProps } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../AppContextProvider';

const OfferCard: React.FC<OfferCardProps> = (props) => {
  const [isLiked, setIsLiked] = useState(false);
  const { isLogged } = useUserContext();

  const navigate = useNavigate();

  const handleClick = () => {
    const offerNameDashed = props.offer.title.toLowerCase().replace(/ /g, '-');
    navigate(`/oferty/${offerNameDashed}/${props.offer.jobOfferReferenceId}`, { state: { manageMode: props.manageMode  } });
  }
  const handleLikeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsLiked(!isLiked);
  }

  return (
    <Card className="mb-3 offer-card" onClick={handleClick}>
      <Row className='w-100'>
        <Col xs={4} md={4} className="offer-card-img-col">
          <Card.Img
            variant="top"
            src={`https://via.placeholder.com/150`}
            className="offer-card-img"
          />
        </Col>
        <Col xs={8} md={8}>
          <Card.Body className="d-flex flex-column justify-content-between">
            <div>
              <Card.Title className="mb-1 offer-card-title">{props.offer.title}</Card.Title>
              <Card.Text className="mb-1 offer-card-text">
                {props.offer.description}
              </Card.Text>
            </div>
            <div>
              {isLogged && !props.manageMode &&
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleLikeClick}
                  className='d-flex justify-content-center align-items-center'
                  style={{ fontSize: '0.7rem', padding: '0.2rem 0.5rem' }}>
                  <i className={isLiked ? 'bi bi-heart-fill white-icon' : 'bi bi-heart white-icon'}></i>
                </Button>
              }
            </div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default OfferCard;