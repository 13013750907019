import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { UserContextType } from './types';
import { getUser } from './Api/Users';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Text from 'react-bootstrap/FormText';
import './Pages/Styles/Spinner.scss';
import './Styles/AppContextProvider.scss';
// Create the context
export const UserContext = createContext<UserContextType | undefined>(undefined);

// Create a provider component
export const UserContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [isEmployer, setIsEmployer] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const logout = () => {
      console.log('wasLogged:', localStorage.getItem('wasLogged'));
      console.log('isLogged:', isLogged);
      if (localStorage.getItem('wasLogged') === 'true' && isLogged === false) {
        setShowAlert(true);
        const timer = setTimeout(() => {
          setShowAlert(false);
          localStorage.setItem('wasLogged', 'false');
        }, 5000); // Hide alert after 5 seconds
        return () => clearTimeout(timer);
      }
    }

    const tryToGetUser = async () => {
      try {
        const response = await getUser();
        if (response.code === 200) {
          setUser(response.data.user);
          setIsLogged(true);
          localStorage.setItem('wasLogged', 'true');
          setIsEmployer(response.data.user.isEmployer);
        }
      } catch (error) {
        console.error('Error while getting user data:', error);
        setIsLogged(false);
        setIsEmployer(false);
        setUser({ firstName: 'Nieznajomy' });
        logout();
      } finally {
        setLoading(false);

      }
    }
    tryToGetUser();

  }, [isLogged]);


  if (loading) {
    console.log(loading);
    return (
      <Container className="loading-container">
      <Container className="login-in-overlay">
        <Spinner animation="border" role="status" variant="primary"/>
        <Text className="sr-only">Ładowanie...</Text>
      </Container>
    </Container>
    );
  }

  return (
    <UserContext.Provider value={{ user, setUser, isLogged, setIsLogged, isEmployer, setIsEmployer }}>
      {showAlert &&
        (
            <Alert variant="danger">Sesja wygasła. Zaloguj się ponownie.</Alert>
        )}
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};