import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import './Styles/Offers.scss';
import '../Styles/Spinner.scss';
import { getAllOffers } from '../../Api/Offers';
import { OfferType } from '../../types';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from "react-router-dom";
import OffersPageContent from '../../Components/Offers/OffersPageContent';


const Offers = () => {
  // const [offerCount, setOfferCount] = useState(255); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [offers, setOffers] = useState<OfferType[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [offersPerPage, setOffersPerPage] = useState(15); // Default to 15 offers per page

  const handleOffersPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOffersPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when offers per page changes
  };

  useEffect(() => {
    console.log('useEffect called');
    const retrieveOffers = async (
      setOffers: React.Dispatch<React.SetStateAction<any[]>>,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      try {
        const response = await getAllOffers();
        console.log(response);
        if (response.code === 200) {
          console.log('Offers retrieved:', response.data);
          setOffers(response.data.offers);
        }
      } catch (error) {
        console.error('Error retrieving offers:', error);
      } finally {
        setLoading(false);
      }
    }
    retrieveOffers(setOffers, setLoading);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the offers to display on the current page
  const indexOfLastOffer = currentPage * offersPerPage;
  const indexOfFirstOffer = indexOfLastOffer - offersPerPage;
  const currentOffers = offers.slice(indexOfFirstOffer, indexOfLastOffer);

  // Calculate total pages
  const totalPages = Math.ceil(offers.length / offersPerPage);

  return (
    <Container className='page-container'>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item active>Oferty</Breadcrumb.Item>
      </Breadcrumb>
      <OffersPageContent
        offersPerPage={offersPerPage}
        handleOffersPerPageChange={handleOffersPerPageChange}
        loading={loading}
        currentOffers={currentOffers}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        manageMode={false}
      />
    </Container>
  );
};

export default Offers;