import React from "react";


const Apply = () => {
    return (
        <div>
            Apply
        </div>
    )
};

export default Apply;