export const payTypeShortName = (payTypeName: string): string => {
    switch (payTypeName) {
        case 'Miesięczna':
            return 'miesiąc';
        case 'Godzinowa':
            return 'godzinę';
        case 'Dzienna':
            return 'dzień';
        case 'Od zlecenia':
            return 'zlecenie';
        default:
            return '';
    }
}
