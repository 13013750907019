import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { payTypeShortName } from "../../Utils/PayTypeUtils";
import { OfferType } from "../../types";
import { Button } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import './Styles/OfferDetails.scss';

const OfferDetails: React.FC<OfferType> = (offer) => {
  console.log(offer);
  console.log("OfferDetails");
  const navigate = useNavigate();
  const location = useLocation();
  const payTypeParsed = payTypeShortName(offer.payType?.name || '');
  const manageMode = location.state?.manageMode || false;

  const handleGoBack = () => {
    navigate('/oferty');
  }

  return (
    <Container className="container-relative">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/oferty" }}>Oferty</Breadcrumb.Item>
        <Breadcrumb.Item active>Szczegóły oferty</Breadcrumb.Item>
      </Breadcrumb>
      <Container className="">
        <Card>
          <Card.Body>
            <Card.Title>{offer.title || 'N/A'}</Card.Title>
            <Card.Text>
              <strong>Stawka:</strong> {offer.pay || 'N/A'} zł/{payTypeParsed}
            </Card.Text>
            <Container>
              {Array.isArray(offer.location) && offer.location.length > 1 ? <strong>Lokalizacje:</strong> : <strong>Lokalizacja:</strong>}
              <br />
              {Array.isArray(offer.location) && offer.location.map((loc, index) => (
                <div key={index} className="location-item">
                  {loc.remote ? (
                    <span>Zdalna</span>
                  ) : (
                    <>
                      {loc.postalCode || ''} {loc.city || ''}
                      <br />
                      {loc.street || ''} {loc.houseNumber || ''}
                      {loc.apartmentNumber ? `/${loc.apartmentNumber}` : ''}
                      <br />
                    </>
                  )}
                </div>
              ))}
              
            </Container>
            <Card.Text>
              <strong>Opis:</strong>
              <br />
              {offer.description || 'N/A'}
            </Card.Text>
            <Button variant="secondary" onClick={handleGoBack}> {`<- Powrót do wszystkich ofert`}</Button>
            { !manageMode &&
              <Button variant="primary" className="ms-2">Wyraź zainteresowanie</Button>
            }
          </Card.Body>
        </Card>
      </Container>
    </Container>
  );
}

export default OfferDetails;