import React from "react";
import './Styles/CustomFooter.scss';

const CustomFooter = () => {
    return (
        <footer className="text-center bg-body-tertiary">
            <p className="text">&copy; 2024 Piotr Kuchnowski</p>
        </footer>
    );
}

export default CustomFooter;