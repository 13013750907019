import React from "react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

const Filters = ({ offersPerPage, handleOffersPerPageChange }: { offersPerPage: number, handleOffersPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void }) => {
    return (
        <Container className='filter-pane'>
          <Form.Group controlId="offersPerPageSelect" className="mb-3">
            <Form.Label>Ofert na stronę</Form.Label>
            <Form.Control
              as="select"
              value={offersPerPage}
              onChange={(event) => handleOffersPerPageChange(event as unknown as React.ChangeEvent<HTMLSelectElement>)}
            >
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={45}>45</option>
              <option value={60}>60</option>
              <option value={75}>75</option>
              <option value={90}>90</option>
            </Form.Control>
          </Form.Group>
        </Container>
    );
}

export default Filters;