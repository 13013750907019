import React from 'react';
import OfferCard from './OfferCard';
import {Container, Row, Col} from 'react-bootstrap';
import { OfferListProps } from '../../types';

const OfferList: React.FC<OfferListProps> = ({ offers, manageMode }) => {
    return (
      <Container>
        <Col>
          {offers.map((offer) => (
            <Row key={offer.jobOfferReferenceId} md={4}>
              <OfferCard
                offer={offer}
                manageMode={manageMode}
              />
            </Row>
          ))}
        </Col>
      </Container>
    );
  };

  export default OfferList;