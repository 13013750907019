import React, { useCallback, useEffect, useState } from "react";
import { useUserContext } from "../AppContextProvider";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { getUser, updateUser } from "../Api/Users";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const Profile = () => {
    const { user, setUser } = useUserContext();
    const [editMode, setEditMode] = useState(false);
    const [buttonText, setButtonText] = useState('Edytuj');
    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [email, setEmail] = useState(user.email || '');
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber || '');
    const [birthDate, setBirthDate] = useState(user.birthDate || '');

    const handleButtonClick = async () => {
        if (editMode) {
            setButtonText('Edytuj');
            await saveUserProfile();
        } else {
            setButtonText('Zapisz');
        }
        setEditMode(!editMode);
    };

    const getUserData = useCallback(async () => {
        try {
            const response = await getUser();
            if (response.code === 200) {
                console.log('User data retrieved successfully');
                setUser(response.data.user);
            } else {
                console.error('Failed to get user data');
            }
        }catch (error) {
            console.error('Error getting user data:', error);
        }
    }, [setUser]);

    const saveUserProfile = async () => {
        try {
            const response = await updateUser(email, firstName, lastName, phoneNumber, birthDate);
            if (response.code === 200) {
                console.log('Profile updated successfully');
                console.log(response);
                setUser(response.data.user);
                console.log(user);
            } else {
                console.error('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    useEffect(() => {
        getUserData();
    }, [getUserData]);

    return (
        <Container className="container-relative">
            <Breadcrumb>
                <Breadcrumb.Item href="/">Strona główna</Breadcrumb.Item>
                <Breadcrumb.Item active>Profil</Breadcrumb.Item>
            </Breadcrumb>
            <Container>
                <Card>
                    <Card.Body>
                        <Card.Title>Profil użytkownika</Card.Title>
                        {editMode ? (
                            <Container>
                                <Form.Control
                                    type="text"
                                    placeholder="Imię"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Nazwisko"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Telefon"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                                <Form.Control
                                    type="date"
                                    placeholder="Data urodzenia"
                                    value={birthDate}
                                    onChange={(e) => setBirthDate(e.target.value)}
                                />
                            </Container>
                        ) : (
                            <Container>
                                <Card.Text>Imię: {user.firstName}</Card.Text>
                                <Card.Text>Nazwisko: {user.lastName}</Card.Text>
                                <Card.Text>E-mail: {user.email}</Card.Text>
                                <Card.Text>Telefon: {user.phoneNumber}</Card.Text>
                                <Card.Text>Data urodzenia: {user.birthDate}</Card.Text>
                            </Container>
                        )}
                        <Button onClick={handleButtonClick}>{buttonText}</Button>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default Profile;