import axios from "axios";
import { CreateOffer } from "../types";

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://192.168.196.4:8085';
const client = axios.create({
  baseURL: `${BASE_URL}/offer`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const getAllOffers = async () => {
    try {
        const response = await client.get("/all");
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message);
        } else {
        throw new Error("An unexpected error occurred");
        }
    }
}

export const getEmployersOffers = async () => {
    try {
        const response = await client.get("/all/employer");
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message);
        } else {
        throw new Error("An unexpected error occurred");
        }
    }
}

export const getOfferById = async (id : string) => {
    try {
        const response = await client.get(`/get?id=${id}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message);
        } else {
        throw new Error("An unexpected error occurred");
        }
    }
}

export const addOffer = async (referenceId : string, offer : CreateOffer) => {
    console.log(referenceId);
    console.log(offer);

    try {
        const response = await client.post("/add", {
            referenceId: referenceId,
            startDate: offer.startDate,
            endDate: offer.endDate,
            description: offer.description,
            title: offer.title,
            pay: offer.pay,
            payType: offer.payType,
            locations: offer.locations,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data?.message || error.message);
        } else {
            throw new Error("An unexpected error occurred");
        }
    }
}
