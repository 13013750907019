import React from "react";
import Container from "react-bootstrap/Container";
import './Styles/Home.scss';

import { useUserContext } from "../AppContextProvider";

const Home = () => {
    const { user } = useUserContext();

    return (
        <div className="home-background">
            <Container className="home-content">
                <h1>Witaj, {user.firstName}!</h1>
                <p>Jeśli masz trochę czasu, a akurat potrzebujesz gotówki, praca dorywcza może być rozwiązaniem.</p>
            </Container>
        </div>
    );
};

export default Home;