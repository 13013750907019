import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import "./Styles/EmployerPanel.scss";
import PanelCard from "../../Components/Employer/PanelCard";
import { useNavigate } from "react-router-dom";

const EmployerPanel = () => {
    const navigate = useNavigate();

    return (
        <Container className="container-relative">
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Strona główna</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/pracodawca" }}>Pracodawca</Breadcrumb.Item>
                <Breadcrumb.Item active>Panel</Breadcrumb.Item>
            </Breadcrumb>
            <Container>
                <Row>
                    <Col md={6} className="mb-4">
                        <PanelCard
                            title="Dodaj ofertę pracy"
                            text="Dodaj nową ofertę pracy dla kandydatów"
                            onClick={() => { navigate("/pracodawca/panel/dodaj-oferte") }}
                        />
                    </Col>
                    <Col md={6} className="mb-4">
                        <PanelCard
                            title="Zarządzaj ofertami"
                            text="Zarządzaj swoimi ofertami pracy"
                            onClick={() => { navigate("/pracodawca/panel/zarzadzaj-ofertami") }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="mb-4">
                        <PanelCard
                            title="Zainteresowani kandydaci"
                            text="Sprawdź kto jest zainteresowany Twoimi ofertami pracy"
                            onClick={() => { navigate("/pracodawca/panel/zainteresowani-kandydaci") }}
                        />
                    </Col>
                    <Col md={6} className="mb-4">
                        <PanelCard
                            title="Profil"
                            text="Zmień dane swojego profilu pracodawcy"
                            onClick={() => { navigate("/pracodawca/panel/profil") }}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>

    )
};

export default EmployerPanel;